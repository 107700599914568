import React from "react";
// import { Link } from "gatsby";
import Lattice from "lattice/lib/components/Lattice";
import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/functional/seo";
import DesignProps from "../components/molecules/design-props";
import Button from "../components/atoms/button";
import DesignMenu from "./design-menu.json";

function DesignGrid() {
  const gridMenu = DesignMenu.find((dm) => dm.slug === "grid");
  return (
    <div className="design__content">
      <SEO title="Design System - Grid" />
      <h2>Grid</h2>
      <p className="design__text p2">
        Quilt uses a grid layout as the foundation for all page and component
        structure. The grid comprises of 12 columns across 6 different break
        points: Desktop HD, Desktop, Tablet, Mobile Large, Mobile Medium, and
        Mobile Small.
      </p>

      <div className="sp-btm-mrg-m">
        {gridMenu.submenu.map((item) => (
          <Button
            className="shared__link"
            route={`/${item.slug}`}
            key={item.slug}>
            {item.title}
          </Button>
        ))}
      </div>
      <div className="design__stories">
        <div className="design__story-section">
          <p className="design__text p2">
            The Lattice component represents the topmost element in the design
            system, like the html or body tag. In most cases, there will only be
            one instance defined.
          </p>
          <DesignProps component={Lattice} title="Lattice Properties" />
        </div>

        <div className="design__story-section">
          <p className="design__text p2">
            The LatticeGrid component represents all media blocks, groups and
            carousels. If the Lattice component were like a deck of cards, the
            LatticeGrid component would be an individual card in the deck.
          </p>
          <DesignProps component={LatticeGrid} title="LatticeGrid Properties" />
        </div>
      </div>
    </div>
  );
}

export default DesignGrid;
